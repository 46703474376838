
.invoice-modal-table-container {
  width: 100%;
  overflow-x: auto;
  min-height: 300px;
}

.invoice-modal-table {
  tbody, thead {
    font-size: 12px;
  }

  td {
    font-size: 12px;
    padding: 10px !important;
  }

}

.invoice-modal-body {
}
