
.delivery-challans-modal-table {
  tbody, thead {
    font-size: 12px;
  }

  td {
    font-size: 12px;
    padding: 10px !important;
  }

}